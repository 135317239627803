(function (window, document) {
	/* ---------------------------------------- [START] 整頁 Scroll 監測 (Before) */
	var pageScrollCheckList = []; // 紀錄物件
	var pageScrollAdd = function (selector, buildFunction) {
		var el = document.querySelector(selector);
		if (el === null) {
			return false;
		}
		pageScrollCheckList.push({
			build: false,
			el: el,
			fun: buildFunction,
		});
	};
	var pageScrollClean = function () {
		pageScrollCheckList = Array.prototype.filter.call(pageScrollCheckList, function (item) {
			return item.build === false;
		});
	};
	/* ---------------------------------------- [END] 整頁 Scroll 監測 (Before) */

	/* ---------------------------------------- [START] Swiper 整頁 Scroll 監測 => 開啟/關閉 autoplay (Before) */
	var swiperScrollAutoplayList = []; // 紀錄物件
	var swiperScrollAdd = function (swiper, el) {
		var obj = {
			swiper: swiper,
			el: el,
		};
		Object.defineProperty(obj, 'inViewport', {
			inViewportCache: null,
			get: function () {
				return swiper.autoplay.running;
			},
			set: function (newValue) {
				if (this.inViewportCache !== newValue) {
					this.inViewportCache = newValue;
					if (newValue) {
						swiper.autoplay.start();
					} else {
						swiper.autoplay.stop();
					}
				}
			},
		});
		swiperScrollAutoplayList.push(obj);
	};
	/* ---------------------------------------- [END] Swiper 整頁 Scroll 監測 => 開啟/關閉 autoplay (Before) */

	/* ---------------------------------------- [START] Tool */
	// function on(target, event, func, option) {
	// 	target = target || window;
	// 	if (window.addEventListener) {
	// 		var opt = option || false;
	// 		target.addEventListener(event, func, opt);
	// 	} else {
	// 		target.attachEvent('on' + event, func);
	// 	}
	// }

	// function off(target, event, func) {
	// 	target = target || window;
	// 	if (window.addEventListener) {
	// 		target.removeEventListener(event, func, false);
	// 	} else {
	// 		target.detachEvent('on' + event, func);
	// 	}
	// }

	// /* 簡單的節流函數 */
	// /* https://www.cnblogs.com/coco1s/p/5499469.html */
	// function throttle(func, wait, mustRun) {
	// 	var timeout;
	// 	var startTime = new Date();

	// 	return function () {
	// 		var context = this;
	// 		var args = arguments;
	// 		var curTime = new Date();

	// 		if (timeout !== undefined) {
	// 			if (window.requestTimeout !== undefined) clearRequestTimeout(timeout);
	// 			else clearTimeout(timeout);
	// 		}
	// 		// 如果達到了規定的觸發時間間隔，觸發 handler
	// 		if (curTime - startTime >= mustRun) {
	// 			func.apply(context, args);
	// 			startTime = curTime;
	// 			// 沒達到觸發間隔，重新設定定時器
	// 		} else {
	// 			if (window.requestTimeout !== undefined) timeout = requestTimeout(func, wait);
	// 			else timeout = setTimeout(func, wait);
	// 		}
	// 	};
	// }
	/* ---------------------------------------- [END] Tool  */

	/* ---------------------------------------- [START] Index Banner */
	function bannerCarouselBuild() {
		const swiperTarget = '#index-banner-carousel';
		const swiperEl = document.querySelector(swiperTarget).parentElement;
		const swiperPageEl = swiperEl.querySelector('.swiper-pagination');
		const swiperNextEl = swiperEl.querySelector('.swiper-button-next');
		const swiperPrevEl = swiperEl.querySelector('.swiper-button-prev');

		const countDownCircle = swiperNextEl.querySelector('circle');

		const speed = 400;
		const autoplay = 5000;

		/* [START] Countdown Circle Animation */
		const tl = gsap.timeline({ ease: 'none' });

		tl.fromTo(
			countDownCircle,
			{
				strokeDashoffset: 100,
			},
			{
				strokeDashoffset: 0,
				duration: (speed + autoplay) / 1000,
			}
		).pause();

		const countDownCircleStart = function () {
			if (window.innerWidth < 640) {
				return false;
			}
			tl.timeScale(1);
			tl.restart();
		};

		const countDownCircleStop = function () {
			if (window.innerWidth < 640) {
				return false;
			}
			tl.timeScale(4);
			tl.reverse();
		};
		/* [END] Countdown Circle Animation */

		/* [START] Custom Animation */
		// Ref: https://codepen.io/udovichenko/pen/LGeQae

		// 移動方向、程度：
		// .index-banner__img-box Max-width
		var interleaveOffset = window.innerWidth >= 1024 ? -0.6770833 : -1;

		var customEffect = {
			onProgress: function (swiper, progress) {
				for (var i = 0; i < swiper.slides.length; i++) {
					var slideProgress = swiper.slides[i].progress;
					var innerOffset = swiper.width * interleaveOffset;
					var innerTranslate = slideProgress * innerOffset;
					swiper.slides[i].querySelector('.index-banner__img').style.transform =
						'translate3d(' + innerTranslate + 'px, 0, 0)';
				}
			},

			onTouchStart: function (swiper) {
				for (var i = 0; i < swiper.slides.length; i++) {
					swiper.slides[i].style.transition = '';
				}
			},

			onSetTransition: function (swiper, speed) {
				for (var i = 0; i < swiper.slides.length; i++) {
					swiper.slides[i].style.transition = speed + 'ms';
					swiper.slides[i].querySelector('.index-banner__img').style.transition = speed + 'ms';
				}
			},
		};
		/* [END] Custom Animation */

		const mySwiper = new Swiper(swiperTarget, {
			effect: 'fade',
			speed: speed,
			slidesPerGroup: 1,
			slidesPerView: 1,
			grabCursor: true,
			loop: true,
			watchSlidesProgress: true,
			preloadImages: false,
			lazy: true,
			watchOverflow: true, // Loop Up Loop
			autoplay: {
				delay: autoplay,
				disableOnInteraction: false,
			},
			pagination: {
				el: swiperPageEl,
			},
			navigation: {
				nextEl: swiperNextEl,
				prevEl: swiperPrevEl,
			},
			on: {
				// Autoplay
				autoplay: function () {
					countDownCircleStart();
				},
				autoplayStart: function () {
					countDownCircleStart();
				},
				autoplayStop: function () {
					countDownCircleStop();
				},

				// Custom Effect
				// Ref: https://codepen.io/udovichenko/pen/LGeQae
				progress(swiper, progress) {
					customEffect.onProgress(swiper, progress);
				},

				touchStart: function (swiper) {
					customEffect.onTouchStart(swiper);
				},

				setTransition(swiper, transition) {
					customEffect.onSetTransition(swiper, transition);
				},
			},
		});

		/* pauseOnMouseEnter Not working，改手寫 */
		swiperEl.addEventListener('mouseenter', function () {
			mySwiper.autoplay.stop();
		});

		swiperEl.addEventListener('mouseleave', function () {
			mySwiper.autoplay.start();
		});

		/* Autoplay Scroll 監測 */
		swiperScrollAdd(mySwiper, swiperEl);
	}

	on(window, 'load', function () {
		pageScrollAdd('#index-banner-carousel', bannerCarouselBuild);
	});
	/* ---------------------------------------- [END] Index Banner */

	/* ---------------------------------------- [START] Index News */
	function newsCarouselBuild() {
		const swiperTarget = '#index-news-carousel';
		const swiperEl = document.querySelector(swiperTarget).parentElement;
		const swiperPageEl = swiperEl.querySelector('.swiper-pagination');
		const swiperNextEl = swiperEl.querySelector('.swiper-button-next');
		const swiperPrevEl = swiperEl.querySelector('.swiper-button-prev');

		const mySwiper = new Swiper(swiperTarget, {
			speed: 400,
			slidesPerGroup: 1,
			slidesPerView: 1,
			spaceBetween: 40,
			loop: true,
			loopFillGroupWithBlank: true, // Loop 模式時，未滿 Group 時，是否要填滿 Group，true => | 1 2 3 | 4    | 1 2 3...
			autoHeight: true,
			// preloadImages: false,
			// lazy: true,
			pagination: {
				el: swiperPageEl,
				clickable: true,
				type: 'bullets',
			},
			navigation: {
				nextEl: swiperNextEl,
				prevEl: swiperPrevEl,
			},
			breakpoints: {
				1024: {
					speed: 600,
					slidesPerGroup: 3,
					slidesPerView: 3,
					spaceBetween: 30,
				},
			},
		});

		var newsCarouselResize = function () {
			if (mySwiper !== null) {
				mySwiper.updateAutoHeight();
			}
		};

		on(window, 'resize', throttle(newsCarouselResize, 50, 100));
	}

	const menuBreakpoint = 1440;
	// 淡入 Init
	function newsParallaxInit(sec, bg) {
		gsap.set(bg, {
			yPercent: -10,
			opacity: 0,
		});
	}

	function newsParallax(sec, bg) {
		// 淡入
		gsap.to(bg, {
			opacity: 1,
			duration: 1,
			scrollTrigger: sec,
		});

		// 簡易滾動視差
		gsap.to(bg, {
			yPercent: window.innerWidth < menuBreakpoint ? 10 : 20,
			ease: 'none',
			scrollTrigger: {
				trigger: sec,
				scrub: true,
			},
		});
	}

	on(window, 'load', function () {
		pageScrollAdd('#index-news-carousel', newsCarouselBuild);

		const sec = document.querySelector('.sec-index-news');
		const bg = sec.querySelectorAll('.bg-box__bg.show-for-large');
		newsParallaxInit(sec, bg);
		pageScrollAdd('#index-news-carousel', function () {
			newsParallax(sec, bg);
		});
	});
	/* ---------------------------------------- [END] Index News */

	/* ---------------------------------------- [START] Index GUC Core */
	function coreParallax() {
		const sec = document.querySelector('.sec-index-core');
		const bg = sec.querySelector('.bg-box');

		gsap.set(bg, {
			yPercent: -50,
		});

		// 簡易滾動視差
		gsap.to(bg, {
			yPercent: 0,
			ease: 'none',
			scrollTrigger: {
				trigger: sec,
				scrub: true,
			},
		});
	}

	on(window, 'load', function () {
		pageScrollAdd('.sec-index-core', coreParallax);
	});
	/* ---------------------------------------- [END] Index GUC Core */

	/* ---------------------------------------- [START] Index GLink */
	// 缺RWD
	// 缺Resize
	var body = document.body;
	var glinkNatureWidth;
	var glinkNatureHeight;

	var glinkBg = document.querySelector('.sec-index-glink .bg-box__bg');

	// 1921+以上的BG鏡射
	var glinkBgClone = glinkBg.cloneNode(true);
	glinkBgClone.classList.add('is-clone');
	glinkBg.parentElement.appendChild(glinkBgClone);

	var glinkCheckBgHeight = function () {
		if (!glinkNatureWidth) {
			return false;
		}

		if (window.innerWidth < 1440) {
			// 背景 size 為: auto 100% 時（高100%），取消計算值
			glinkBg.style.height = '';
		} else {
			// 賦予計算值
			var bgHeight = (body.clientWidth * glinkNatureHeight) / glinkNatureWidth;
			glinkBg.style.height = bgHeight + 'px';
			
			if (window.innerWidth > 1920) {
				glinkBgClone.style.height = bgHeight + 'px';
			}
		}
	};

	glinkBg.addEventListener('lazy:loaded', function (e) {
		glinkNatureWidth = e.detail.img.naturalWidth;
		glinkNatureHeight = e.detail.img.naturalHeight;

		glinkCheckBgHeight();
		window.addEventListener('resize', throttle(glinkCheckBgHeight, 50, 100));
	});
	/* ---------------------------------------- [END] Index GLink */

	/* ---------------------------------------- [START] 整頁 Scroll 監測 (After) */
	// var pageScrollThrottle = null;
	var pageScrollHandler = function () {
		if (pageScrollCheckList.length === 0) {
			return false;
		} // 因為改成 Page Global 執行，所以不會停止

		for (var i = 0; i < pageScrollCheckList.length; i++) {
			if (isInViewport(pageScrollCheckList[i].el)) {
				pageScrollCheckList[i].fun();
				pageScrollCheckList[i].build = true;
			}
		}
		pageScrollClean();
		// if (pageScrollCheckList.length === 0) {
		// 	off(window, 'scroll', pageScrollThrottle);
		// }
	};
	// on(window, 'load', function () {
	// 	pageScrollThrottle = throttle(pageScrollHandler, 50, 1000); // 節流作用
	// 	on(window, 'scroll', pageScrollThrottle);
	// 	pageScrollHandler();
	// });
	/* ---------------------------------------- [END] 整頁 Scroll 監測 (After) */

	/* ---------------------------------------- [START] Swiper 整頁 Scroll 監測 => 開啟/關閉 autoplay (After) */
	// var swiperScrollThrottle = null;
	var swiperScrollHandler = function () {
		if (swiperScrollAutoplayList.length === 0) {
			return false;
		}

		for (var i = 0; i < swiperScrollAutoplayList.length; i++) {
			swiperScrollAutoplayList[i].inViewport = isInViewport(swiperScrollAutoplayList[i].el);
		}
	};
	// on(window, 'load', function() {
	// 	swiperScrollThrottle = throttle(swiperScrollHandler, 50, 1000); // 節流作用
	// 	on(window, 'scroll', swiperScrollThrottle);
	// 	swiperScrollHandler();
	// });
	/* ---------------------------------------- [END] Swiper 整頁 Scroll 監測 => 開啟/關閉 autoplay (After) */

	/* ---------------------------------------- [START] Page Global Loaded & Scroll */
	var pageGlobalScrollThrottle = null; // 紀錄 Throttle 物件
	var pageGlobalScroll = function () {
		pageScrollHandler();
		swiperScrollHandler();
	};
	on(window, 'load', function () {
		pageGlobalScrollThrottle = throttle(pageGlobalScroll, 50, 1000); // 節流作用
		on(window, 'scroll', pageGlobalScrollThrottle);
		pageGlobalScroll();
	});
	/* ---------------------------------------- [END] Page Global Loaded & Scroll */
})(window, document);
